@import '~antd/dist/antd.less';

.ant-breadcrumb ol {
  flex-wrap: nowrap !important;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
  text-align: left;
}

.ant-table-cell {
  word-break: keep-all;
}

.ant-input-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.75) !important;
}

@media (max-width: 600px) {
  .ant-picker-panels {
    flex-wrap: wrap !important;
  }
  .ant-picker-content,
  .ant-picker-date-panel {
    width: 100% !important;
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

@media (max-width: 575px) {
  .ant-form-item {
    margin-bottom: 14px !important;
  }
}

@primary-color: #e83b69;